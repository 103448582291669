/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


    // Bootstrap - Dropdown Hover
    /*
    function allowDropdownHover() {
        if($(window).width() > 767){
            $('.navbar [data-toggle="dropdown"]').bootstrapDropdownHover({
                clickBehavior: 'default'
            });
        }   
    }
    allowDropdownHover();
    window.onresize = allowDropdownHover; // Call the function on resize
    */


    // Scroll to top
    $().UItoTop();


    // Enable the Owl Carousel
    if ( $('.owl-carousel').length > 0 ) {
         var defaultOwlOptions = {
            items : 2, // above 1000px
            itemsCustom: [[0, 1], [400, 1], [700, 2], [1000, 2], [1200, 2]],
            itemsScaleUp: true,
            lazyLoad : true,
            pagination : false,
            autoPlay: 10000,
            nav: true,
            navText: ["", ""],
            stopOnHover: true
        };
        $('.owl-carousel').each(function() {
            owlOptions = defaultOwlOptions;
            var item_number = /owl-carousel-([0-9])/.exec($(this).attr('class'));
            if ( item_number !== null ) {
                switch ( parseInt( item_number[1] ) ) {
                    case 1 : 
                       owlOptions.itemsCustom = [[0, 1]]; 
                       break;
                    case 2 :
                       owlOptions.itemsCustom = [[0, 1], [400, 1], [700, 2], [1000, 2], [1200, 2]];
                       break;
                    case 3 :
                       owlOptions.itemsCustom = [[0, 1], [600, 2], [900, 3]]; 
                       break;
                    case 4 :
                       owlOptions.itemsCustom = [[0, 1], [600, 2], [900, 3], [1000, 4]]; 
                       break;
                    case 5 :
                       owlOptions.itemsCustom = [[0, 1], [400, 2], [600, 3], [900, 4], [1000, 5]]; 
                       break;
                    case 6 :
                       owlOptions.itemsCustom = [[0, 2], [400, 3], [600, 4], [900, 5], [1000, 6]]; 
                       break;
                }
            }

            if ( $(this).attr('class').search(/owl-carousel-pagination/) > 0 ) {
                owlOptions.pagination = true;
                owlOptions.navigation = false;
            } else {
                owlOptions.pagination = false;
                owlOptions.navigation = true;
            }

            $(this).owlCarousel( owlOptions );

        });
           
    }


    // The Pricing Table
    $('.pricing .pricing-plans table tfoot .value-buttons .highlight').mouseenter(function() {
                $('.pricing .pricing-plans table tfoot .value-callout .highlight').css('background-color', '#3498db');
    }).mouseleave(function() {
                $('.pricing .pricing-plans table tfoot .value-callout .highlight').css('background-color', '#bc1117');
    });


    // External Links
    $('a[rel~="external"]').attr('target', '_blank');

    // Accordion
//    $('.accordion > li:eq(0) a').addClass('active').next().slideDown();
    $('.accordion a').click(function(j) {
        var dropDown = $(this).closest('li').find('p');
        $(this).closest('.accordion').find('p').not(dropDown).slideUp();
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).closest('.accordion').find('a.active').removeClass('active');
            $(this).addClass('active');
        }
        dropDown.stop(false, true).slideToggle();
        j.preventDefault();
    });


})(jQuery); // Fully reference jQuery after this point.
